//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
export default {
  data: function data() {
    return {
      scheduleCalendar: null,
      events: new Array(),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false
    };
  },
  props: {
    showCalendar: Boolean
  },
  methods: {
    prev: function prev() {
      this.$refs.scheduleCalendar.prev();
    },
    next: function next() {
      this.$refs.scheduleCalendar.next();
    },
    showEvent: function showEvent(_ref) {
      var _this2 = this;

      var nativeEvent = _ref.nativeEvent,
          event = _ref.event;

      var open = function open() {
        _this2.selectedEvent = event;
        _this2.selectedElement = nativeEvent.target;
        setTimeout(function () {
          _this2.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColor: function getEventColor(event) {
      return event.color;
    },
    updateEvents: function updateEvents() {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "calendar/visits"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.events = data;
      }).catch(function (error) {
        _this.logError(error);
      });
      /*this.events.push({
        name: 'Installation Mobile Devices',
        start: new Date(),
        end: new Date('2021-01-14'),
        details: '<h1>Hello World</h1>',
        color: 'cyan',
        timed: true,
      })*/

    }
  },
  mounted: function mounted() {
    this.updateEvents();
    this.$refs.scheduleCalendar.checkChange();
  }
};