import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import { toSafeInteger, toNumber } from "lodash";
export default {
  methods: {
    CreateLineItems: function CreateLineItems(param) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        try {
          var _this = _this2;
          var lineItem = param.formData;
          var lineItemFormData = new Array();

          for (var i = 0; i < lineItem.length; i++) {
            lineItemFormData.push({
              id: toSafeInteger(lineItem[i].id) || null,
              product: toSafeInteger(lineItem[i].product_id) || null,
              group: lineItem[i].group,
              group_primary: lineItem[i].group_primary,
              product_type: lineItem[i].product_type,
              to_equipment: lineItem[i].to_equipment,
              has_warranty: toSafeInteger(lineItem[i].has_warranty),
              warranty: lineItem[i].warranty,
              description: lineItem[i].description,
              quantity: toSafeInteger(lineItem[i].quantity) || 1,
              rate: toNumber(lineItem[i].rate) || 0
            });
          }

          var formData = {
            line_items: lineItemFormData
          };
          formData[param.type] = param.parent;

          if (param.visit) {
            formData["visit"] = param.visit;
          }

          _this.formLoading = true;

          _this.$store.dispatch(CLEAR_ERROR, {});

          _this.$store.dispatch(POST, {
            url: "line-item",
            data: formData
          }).then(function (response) {
            resolve(response);
          }).finally(function () {
            _this.formLoading = false;
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};