<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="customerBillingDialog"
      :max-width="dialogWidth"
      content-class="customer-billing-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select or create a Billing Location
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            color="cyan"
            v-on:click="
              $emit('close', true);
              $emit('resetAll', true);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which company address would you like to create this for?
              </h5>
              <table>
                <tr>
                  <td width="500">
                    <v-text-field
                      ref="search-customer-billing"
                      v-model.trim="customerBilling"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <template v-if="getPermission('billing:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createBillingDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Address
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="customerBillingList.length > 0">
                        <v-list-item
                          v-on:click="selectCustomerBilling(data)"
                          v-for="(data, index) in customerBillingList"
                          :key="index"
                          link
                        >
                          <v-list-item-icon class="mr-3">
                            <v-icon large>mdi-home-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <div>
                              <Barcode
                                class="my-0 mt-0 mb-0 mr-1"
                                :barcode="data.barcode"
                              ></Barcode>
                            </div>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ getFormattedBilling(data) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Billing(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createBillingDialog"
      :commonDialog="createBillingDialog"
      :dialogWidth="768"
    >
      <template v-slot:title>Create Billing Location</template>
      <template v-slot:body>
        <v-form
          ref="createBilling"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createBilling"
        >
          <DialogBillingAddress
            :isDialog="true"
            v-on:saveBillingAddress="updateBilling"
          >
          </DialogBillingAddress>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createBilling"
          >Save Billing Location
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createBillingDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogBillingAddress from "@/view/pages/customer/create-or-update/Billing-Address.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CustomerMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customerBilling: null,
      timeoutLimit: 500,
      timeout: null,
      billingAddress: new Object(),
      createBillingDialog: false,
      customerBillingList: new Array(),
    };
  },
  watch: {
    customerBilling(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerBilling();
        }, _this.timeoutLimit);
      }
    },
    customer() {
      this.searchCustomerBilling(null);
    },
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false,
    },
    customerBillingDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  components: {
    Dialog,
    Barcode,
    DialogBillingAddress,
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomerBilling();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomerBilling();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.customerBilling = null;
        _this.isFocused = false;
      });
    },
    searchCustomerBilling() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getCustomerBilling(_this.customer, _this.customerBilling)
        .then((response) => {
          _this.customerBillingList = response;
          if (
            !_this.disabledAutoSelect &&
            _this.customerBillingList.length === 1
          ) {
            _this.$emit(
              "selectCustomerBilling",
              _this.customerBillingList[0].id
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createBillingDialog = false;
          _this.searchLoader = false;
        });
    },
    selectCustomerBilling(param) {
      this.$emit("selectCustomerBilling", param.id);
    },
    getFormattedBilling(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country.name);
      }
      return tempArr.join(", ");
    },
    updateBilling(param) {
      this.billingAddress = param;
    },
    createBilling() {
      const _this = this;
      if (!_this.$refs.createBilling.validate()) {
        return false;
      }
      let addressFormData = new Array();
      addressFormData.push(_this.billingAddress);
      const formData = _this.lodash.pickBy(
        addressFormData,
        _this.lodash.identity
      );

      if (_this.lodash.isEmpty(formData) === false) {
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});
        _this.$store
          .dispatch(PUT, {
            url: "address/" + _this.customer,
            data: { address: formData },
          })
          .then(() => {
            _this.searchCustomerBilling();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
  },
  mounted() {
    this.searchCustomerBilling();
    this.$refs["search-customer-billing"].focus();
  },
  computed: {
    isSearching() {
      return this.customerBilling && this.customerBilling.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Billing Location...";
    },
  },
};
</script>
